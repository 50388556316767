<template>
  <v-container>
    <portal to="appBarContentLeft">
      <span class="headline font-weight-light">Enrollment</span>
    </portal>
    <v-row v-if="formInput" class="enrollment">
      <EnrollmentErrorDialog ref="enrollmentError" />
      <!-- Patient Form -->
      <v-col cols="12">
        <v-card outlined>
          <v-toolbar dense flat>
            <v-toolbar-title>Patient Information</v-toolbar-title>
            <v-spacer />
            <v-btn text color="primary" class="px-6" :disabled="!this.ownerPatientId" @click="clearForm">Reset</v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="ownerPatientId"
                  label="Search by Patient ID (Required)"
                  dense
                  required
                  hide-details
                  :rules="rules.required"
                  autofocus
                  @input="clearForm"
                  @keypress.enter="searchForPatient"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn
                  depressed
                  color="primary"
                  block
                  class="font-weight-bold py-5"
                  :loading="formState === 'busy'"
                  :disabled="!this.ownerPatientId"
                  @click="searchForPatient"
                  >Search</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert v-if="formState === 'not_found'" dense outlined type="info">
                  It appears this patient has not been registered with NovoRPM.
                  <br />Please fill out the form to continue.
                </v-alert>
              </v-col>
            </v-row>
            <v-form ref="patientForm" class="form" :disabled="formState === ''" v-model="isPatientFormValid">
              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    outlined
                    v-model="formInput.firstName"
                    :rules="rules.required"
                    label="First Name"
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    outlined
                    v-model="formInput.lastName"
                    :rules="rules.required"
                    label="Last Name"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <v-select
                    outlined
                    v-model="formInput.gender"
                    :rules="rules.required"
                    :items="genderOptions"
                    label="Sex"
                    required
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    outlined
                    v-mask="mask.birthdate"
                    :rules="rules.required"
                    v-model="formInput.birthdate"
                    label="Date of Birth"
                    required
                    dense
                    hint="MM/DD/YYYY"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    outlined
                    v-model="formInput.primaryPhone"
                    label="Primary Phone"
                    v-mask="mask.phone"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    outlined
                    v-model="formInput.smsPhone"
                    label="Phone for Messaging"
                    v-mask="mask.phone"
                    dense
                    hint="This number used for messaging"
                    persistent-hint
                    @blur="checkSMSPhone"
                    :error="invalidSmsPhone"
                  >
                    <v-icon color="primary" small slot="append">mdi-message-outline</v-icon>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    outlined
                    v-model="formInput.email"
                    type="email"
                    :rules="rules.email"
                    label="Email"
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-if="
                      partner &&
                        (partner._id === '5f3d59e4e91123118a47e171' || partner._id === '5e39227d1dd9b10d0bd42895')
                    "
                    dense
                    outlined
                    label="Medicare Advantage Number"
                    v-model="formInput.medicareAdvantageNumber"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-row no-gutters class="mb-4">
                    <div class="subtitle-1">Mailing Address</div>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        outlined
                        v-model="formInput.address.line1"
                        :rules="rules.required"
                        label="Street Address"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        outlined
                        v-model="formInput.address.line2"
                        label="Street Address Line 2"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-text-field
                        outlined
                        :rules="rules.required"
                        v-model="formInput.address.city"
                        label="City"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-select
                        outlined
                        :rules="rules.requiresValidState(stateList)"
                        v-model="formInput.address.state"
                        label="State"
                        :items="stateList"
                        required
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-text-field
                        outlined
                        :rules="rules.required"
                        v-model="formInput.address.zip"
                        v-mask="mask.zip"
                        label="Postal Code"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              :disabled="!isPatientFormValid"
              :loading="creating"
              @click="createAndContinue"
            >
              Create Patient and Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="5"> </v-col>
      <!-- Device Selection -->
    </v-row>
  </v-container>
</template>

<style lang="scss">
.enrollment {
  .form {
    // background: red;
    [class^='col-'],
    .col {
      // background: yellow;
      padding-top: 0;
      // padding-bottom: 0;
    }
    .v-input {
      padding-top: 0;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
  }
  .product-form-disabled {
    opacity: 0.65;
  }
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import EnrollmentErrorDialog from '@/components/dialogs/EnrollmentErrorDialog'
import moment from 'moment'
import { getRefreshToken } from '../shared-ui/store/sso'

const defaultFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  smsPhone: '',
  gender: '',
  birthdate: '',
  medicareAdvantageNumber: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
  },
}

export default {
  name: 'ChartCreationDialog',
  mixins: [HelperMixin],
  components: { EnrollmentErrorDialog },
  data() {
    return {
      ownerPatientId: '',
      formState: '', // '', busy, found, not_found
      isPatientFormValid: false,
      invalidSmsPhone: false,
      mask: {
        phone: '###-###-####',
        zip: '#####',
        birthdate: '##/##/####',
      },
      genderOptions: ['MALE', 'FEMALE'],
      formInput: defaultFormValues,
      valid: false,
      rules: {
        requiresValidState: stateList => [v => !!v || 'Required', v => stateList.includes(v)],
        required: [v => !!v || 'Required'],
        email: [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
      },
      potentialPatientMatches: [],
      creating: false,
    }
  },
  computed: {
    ...mapState('auth', ['partner', 'authToken']),
    ...mapState('enrollment', ['currentPatient']),
  },
  methods: {
    ...mapActions('enrollment', ['createPatient', 'findCandidate', 'getPatient', 'setCurrentPatient']),
    async createAndContinue() {
      this.creating = true
      try {
        let patient = await this.createPatient({
          owner: {
            id: this.$route.params.id,
            ref: 'Partners',
          },
          ownerPatientId: this.ownerPatientId,
          firstName: this.formInput.firstName,
          lastName: this.formInput.lastName,
          gender: this.formInput.gender,
          birthdate:
            new Date(this.formInput.birthdate)
              .toISOString()
              .split('T')
              .shift() + 'T00:00:00Z',
          primaryPhone: this.formInput.primaryPhone,
          smsPhone: this.formInput.smsPhone,
          email: this.formInput.email,
          address: this.formInput.address,
        })
        this.creating = false
        // send to devices screen with query needsDevice
        this.$router.push({
          name: 'Devices',
          params: { id: patient.id },
          query: { needsDevice: true },
        })
      } catch (e) {
        this.creating = false
        this.$refs.enrollmentError.open({
          title: 'Error Creating Patient',
          text: `There was an error creating the patient. Please try again. If the issue occurs again, please notify support.`,
        })
      }
    },
    async checkSMSPhone() {
      if (this.formInput.smsPhone) {
        let patient = await this.getPatient({
          smsPhone: this.formInput.smsPhone,
        })
        this.invalidSmsPhone = false
        if (patient && patient.id !== this.currentPatient.id) {
          this.invalidSmsPhone = true
          this.$refs.enrollmentError.open({
            title: 'Phone Number Already In Use',
            text: `The phone number you've selected is already being used by another patient. For privacy reasons, numbers cannot be shared by two patients. Please check the number and try again. You may continue enrollment with another number or by leaving the field blank.`,
          })
        }
      }
      return !this.invalidSmsPhone
    },
    validateForm() {
      this.$refs.patientForm.validate()
    },
    clearForm() {
      if (this.$refs.patientForm) {
        this.$refs.patientForm.resetValidation()
      }
      this.formState = ''
      this.$set(this, 'formInput', Object.assign({}, defaultFormValues))
      this.setCurrentPatient(null)
    },
    formatDate(date) {
      if (!date) return null
      return moment(date.split('T').shift()).format('L')
    },
    async searchForPatient() {
      this.formState = 'busy'
      const ownerId = this.$route.params.id

      if (this.ownerPatientId) {
        // Search for patient
        const patient = await this.getPatient({
          ownerId,
          ownerPatientId: this.ownerPatientId,
        })

        if (patient) {
          // send to devices page to add a device
          this.$router.push({
            name: 'Devices',
            params: { id: patient.id },
            query: { needsDevice: true },
          })
          return
        }
        // Search for candidate
        const candidate = await this.findCandidate({
          ownerId,
          ownerPatientId: this.ownerPatientId,
        })
        if (candidate) {
          // send to devices page to add a device
          this.$router.push({
            name: 'Devices',
            params: { id: patient.id },
            query: { needsDevice: true },
          })
          return
        }

        this.formState = 'not_found'
      }
    },
  },
  async mounted() {
    let refreshToken = await getRefreshToken(this.authToken)
    if (!refreshToken) {
      console.log('error authenticating user')
    }
  },
}
</script>
