<template>
  <v-dialog v-model="show" max-width="480" persistent scrollable>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title class="pl-2">{{title}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>{{text}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed dark color="primary" @click="close">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  data: () => {
    return {
      show: false,
      title: '',
      text: '',
    }
  },
  methods: {
    open({ title = '', text = '' }) {
      this.title = title
      this.text = text
      this.show = true
    },
    close() {
      this.show = false
    },
  },
}
</script>
